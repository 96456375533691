import { HomeText } from "./home-text";
import { isMobile } from "react-device-detect";
import { ScrollToBottomButton } from "./scroll-to-bottom-button";
export const Home = () => {
  return (
    <div
      id="home-div"
      style={{
        backgroundImage: 'url("/assets/bg.jpg")',
        backgroundSize: "cover",
      }}
      className="w-full bg-black flex flex-col items-center h-screen"
    >
      <div
        style={{ left: isMobile ? 0 : "80px", top: isMobile ? "30%" : "50%" }}
        className="absolute  px-10"
      >
        <HomeText />
      </div>
      <ScrollToBottomButton />
    </div>
  );
};
