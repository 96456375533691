import { isMobile } from "react-device-detect";
import { ScrollToTopButton } from "./scroll-to-top-button";
export const Contact = () => {
  return (
    <div
      id="contact-div"
      style={{
        backgroundImage: 'url("/assets/bg2.jpg")',
        backgroundSize: "cover",
      }}
      className="w-full h-screen bg-[rgba(0,0,0,1)] relative"
    >
      <div
        style={{
          left: isMobile ? "15%" : "80px",
          top: isMobile ? "15%" : "30%",
        }}
        className="absolute px-10"
      >
        <p className="text-4xl text-[#2f2f2f]">
          If you want more information,{" "}
        </p>
        <a
          className="text-2xl text-[#404040]"
          href="mailto:mjkokaly@gmail.com"
        >
          please contact here
        </a>
      </div>
      <ScrollToTopButton />
    </div>
  );
};
