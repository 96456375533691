import { Navbar } from "./components/navbar";
import { Home } from "./components/home";
import { Contact } from "./components/contact";
import { Projects } from "./components/projects";

function App() {
  return (
    <div
      style={{ fontFamily: "Montserrat, sans-serif !important" }}
      className="flex flex-col"
    >
      <Navbar />
      <Home />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
