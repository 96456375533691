import { useState } from "react";

export const Navbar = ({ fixed }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <>
      <nav
        style={{ background: navbarOpen ? "rgb(35 65 91)" : "rgb(55 65 81)" }}
        className="lg:text-md text-sm w-full z-20 fixed flex flex-wrap items-center justify-between px-2 py-3 opacity-90"
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between items-center lg:w-auto lg:static lg:block lg:justify-start">
            <p
              className="cursor-pointer font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap  text-white"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Majdi Kokaly - Frontend Developer
            </p>
            {!navbarOpen && (
              <img
                onClick={() => setNavbarOpen(!navbarOpen)}
                className="cursor-pointer h-6 w-6 lg:hidden"
                src="/assets/bars.svg"
                alt="bars"
              />
            )}
            {navbarOpen && (
              <img
                onClick={() => setNavbarOpen(!navbarOpen)}
                className="cursor-pointer h-6 w-6 lg:hidden"
                src="/assets/cross.svg"
                alt="cross"
              />
            )}
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  target="_blank"
                  className="px-3 py-2 flex items-center font-bold leading-snug text-white hover:opacity-75"
                  href="https://www.linkedin.com/in/majdi-kokaly/"
                  rel={"noreferrer"}
                >
                  <img
                    className="cursor-pointer h-8 w-8"
                    src="/assets/linkedin.png"
                    title="Visit my LinkedIn profile"
                    alt="LinkedIn"
                  />

                  <span className="ml-2">LinkedIn</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center font-bold leading-snug text-white hover:opacity-75"
                  href="https://gitlab.com/MajdiKB"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <img
                    className="cursor-pointer h-8 w-8"
                    src="/assets/gitlab.png"
                    title="Check my GitLab projects"
                    alt="GitLab"
                  />

                  <span className="ml-2">GitLab</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center font-bold leading-snug text-white hover:opacity-75"
                  href="/Majdi_Kokaly_Frontend_Developer.pdf"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <img
                    className="cursor-pointer h-8 w-8 rounded-3xl"
                    src="/assets/cv.png"
                    alt="Majdi Kokaly"
                  />
                  <span className="ml-2">My CV</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
