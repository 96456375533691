import { useState } from "react";
import { isMobile } from "react-device-detect";

export const Projects = () => {
  //get the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener("resize", handleResize);
  const minWindowWidth = 1560;
  return (
    <div className="w-full" id="projects-div">
      <div className="w-full">
        <div
          style={{
            justifyContent:
              isMobile || windowWidth ? "center" : "space-between",
          }}
          className="flex flex-wrap w-full bg-gray-500"
        >
          <div
            style={{
              width: isMobile || windowWidth < minWindowWidth ? "100%" : "50%",
            }}
          >
            <a
              target="_blank"
              href="http://www.dynatech2012.com"
              rel="noreferrer"
            >
              <img
                alt="Dynatech2012"
                title="Go to Dynatech2012 website"
                src="/assets/dynatech2012.png"
                className="w-full h-full object-cover"
              />
            </a>
          </div>
          <div
            style={{
              width: isMobile || windowWidth < minWindowWidth ? "100%" : "50%",
            }}
            className="text-[#DCDCDC] p-10 flex flex-col justify-center"
          >
            <p
              style={{
                marginTop:
                  isMobile || windowWidth < minWindowWidth ? 0 : "20px",
              }}
              className="text-left w-full text-4xl"
            >
              Dynatech2012
            </p>
            <p className="text-sm text-left">React & Firebase</p>
            <p className="text-left w-full text-lg mt-6">
              Digital partner, collaborating with clients across diverse
              industries, empowering their technology-driven ventures. Whether
              it's pioneering software or innovative hardware projects, Dynatech
              step in to transform their vision into a refined, market-ready
              reality.
            </p>
            <p className="text-left w-full text-lg mt-6">
              At Dynatech core, Dynatech prioritize transparency, ensuring clear
              expectations and delivering top-notch products on-time and within
              budget. Dynatech goal is to establish a lasting partnership with
              you, seamlessly integrating Dynatech services into your business
              journey. Let's embark on this transformative tech journey
              together.
            </p>
            <p className="mt-10 text-sm text-right">
              <a
                target="_blank"
                href="http://www.dynatech2012.com"
                rel="noreferrer"
                className="hover:text-gray-400"
              >
                www.dynatech2012.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          flexFlow:
            isMobile || windowWidth < minWindowWidth ? "column-reverse" : "row",
        }}
        className="flex flex-wrap w-full bg-gray-800"
      >
        <div
          style={{
            width: isMobile || windowWidth < minWindowWidth ? "100%" : "50%",
          }}
          className="text-[#DCDCDC] p-10 flex flex-col justify-center"
        >
          <p
            style={{
              marginTop: isMobile || windowWidth < minWindowWidth ? 0 : "20px",
            }}
            className="text-left w-full text-4xl"
          >
            Plan to CAD
          </p>
          <p className="text-sm text-left">React & Firebase</p>
          <p className="text-left w-full text-lg mt-6">
            Team of researchers, designers and engineers who are passionate
            about using technology to enhance the process of vectorizing
            drawings. The company goal is to provide better solutions to you and
            expedite the digitization process.
          </p>
          <p className="text-left w-full text-lg mt-6">
            The specialization lies in converting drawings into
            industry-standard formats like DXF. Through the use of advanced
            technology, the company is able to accurately and rapidly convert
            certain extensions (PDF, JPG, PNG, TIFF or BMP) into DXF format,
            making it effortless for you to share and use your files.
          </p>
          <p className="mt-10 text-sm text-right">
            <a
              target="_blank"
              href="http://www.plantocad.com"
              rel="noreferrer"
              className="hover:text-gray-400"
            >
              www.plantocad.com
            </a>
          </p>
        </div>
        <div
          style={{
            width: isMobile || windowWidth < minWindowWidth ? "100%" : "50%",
          }}
        >
          <a target="_blank" href="http://www.plantocad.com" rel="noreferrer">
            <img
              alt="PlantoCAD"
              title="Go to PlantoCAD website"
              src="/assets/plantocad.png"
              className="w-full h-full object-cover"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
