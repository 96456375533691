import { useState } from "react";
import { isMobile } from "react-device-detect";
import TypeWriterEffect from "react-typewriter-effect";

export const HomeText = () => {
  const [homeWords] = useState([
    "Welcome!",
    "I'm Majdi Kokaly, Frontend Developer",
    "Next.js & React & Firebase & Tailwind",
    "Agile Methodology",
  ]);

  return (
    <TypeWriterEffect
      textStyle={{
        color: "#DCDCDC",
        textAlign: "left",
        fontWeight: 700,
        fontSize: isMobile ? 30 : 50,
      }}
      startDelay={1000}
      cursorColor="white"
      multiText={homeWords ? homeWords : [""]}
      multiTextDelay={2000}
      typeSpeed={120}
      multiTextLoop={true}
    />
  );
};
