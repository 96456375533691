import { isMobile } from "react-device-detect";

export const ScrollToBottomButton = () => {
  const scrollToContact = () => {
   window.scrollTo({ top: (window.innerHeight), behavior: "smooth" });
  };
  return (
    <div className="w-full">
    <div  style={{ right: !isMobile ? "30px" : "15px"}} className="text-[#DCDCDC] absolute bottom-8 text-xs">
      <p className="text-white">latest projects</p>
    </div>
      <div
        style={{ right: !isMobile ? "50px" : "30px"}}
        className="absolute bottom-12"
      >
        <img
          className="cursor-pointer"
          onClick={scrollToContact}
          alt="scroll down"
          title="scroll down"
          src="/assets/button-scroll-down.svg"
        />
      </div>
      <div
        style={{ right: !isMobile ? "50px" : "30px"}}
        className="absolute bottom-16"
      >
        <img
          className="cursor-pointer"
          onClick={scrollToContact}
          alt="scroll down"
          title="scroll down"
          src="/assets/button-scroll-down.svg"
        />
      </div>
    </div>
  );
};
