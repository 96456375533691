import { isMobile } from "react-device-detect";

export const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="w-full">
          <div
        style={{ right: !isMobile ? "50px" : "30px"}}
        className="absolute bottom-12"
      >
        <img
          className="cursor-pointer rotate-180"
          onClick={scrollToTop}
          alt="scroll top"
          title="scroll top"
          src="/assets/button-scroll-down.svg"
        />
      </div>
      <div
        style={{ right: !isMobile ? "50px" : "30px"}}
        className="absolute bottom-8"
      >
        <img
          className="cursor-pointer rotate-180"
          onClick={scrollToTop}
          alt="scroll top"
          title="scroll top"
          src="/assets/button-scroll-down.svg"
        />
      </div>
    </div>
  );
};
